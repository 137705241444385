import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import MyExpenseList from './MyExpenseList';
import Loader from '../../components/modal/Loader';
import FileAttachedModal from '../../components/modal/FileAttachedModal';
import Button from '../../components/button/Button';
import ModifyRoutes from './ModifyRoutes';
import PopupModal from '../../components/modal/PopupModal';
import {
  fetchDayWiseExpense,
  fetchAttachmentDayWise,
  deletedAttachmentDayWise,
  fetchDayWiseTotalExpense,
  getExpenseDayWiseAll,
  expenseUpdateDateWiseSubmit,
  // getAttachmentDaywise
  } from '../../redux/slice/myExpenseDayWiseSlice';

  // images
import attachmentIcon from '/assets/images/attachmentIcon.png';
import removeIcon from '/assets/images/removeIcon.png';
import leftArrowIcon from '/assets/images/leftArrowIcon.png';

import { userInfo } from '../../utils/Constant';
const { employeeId } = userInfo;
const MyExpenseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isLoading,
    apiError,
    errorMessage,
    dayWiseExpense,
    attachmentDayWise,
    dayWiseTotalExpense,
  } = useSelector(getExpenseDayWiseAll);


  // console.log("........sadsdda");

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  useEffect(() => {
    dispatch(fetchDayWiseTotalExpense());
    const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
    if (selectedExpenseData) {
      const { activityDate } = JSON.parse(selectedExpenseData);
      dispatch(fetchDayWiseExpense(activityDate));
    }
  }, [dispatch, isDrawerOpen]);

  // useEffect(() => {
  //   dispatch(fetchAttachmentDayWise());
  // }, [dispatch]);


  useEffect(() => {
    const fetchAttachments = async () => {
      const response = await dispatch(fetchAttachmentDayWise());
      console.log('Attachment response:', response);
    };

    fetchAttachments();
  }, [dispatch]); // Ensure correct dependencies are added here



  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const result = await dispatch(fetchAttachmentDayWise());
        if (!result.payload || result.payload.length === 0) {
          console.warn("No attachments found. Retrying...");
          // Retry logic (optional)
        } else {
          console.log("Attachments fetched successfully:", result.payload);
        }
      } catch (error) {
        console.error("Error fetching attachments:", error);
      }
    };

    fetchAttachments();
  }, [dispatch]);


  const handleNavigate = () => {
    navigate('/my-expense');
    localStorage.removeItem('SelectedExpenseData');
  };

  const handleDeleteAttachment = async (item) => {
    console.log("dasdsadasdddddddddddddddddddd");
    console.log("expendId,expenseOrSundry?????",);
    await dispatch(deletedAttachmentDayWise(item));
    dispatch(fetchAttachmentDayWise());
    dispatch(fetchDayWiseTotalExpense());
  };

  const handlerNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  const handlerSaveUpdate = () => {
    let { total } = dayWiseTotalExpense;
    const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
    const { activityDate, expenseId } = JSON.parse(selectedExpenseData);
    const data = {
      employeeId,
      total,
      activityDate,
      expenseId,
    };
    dispatch(expenseUpdateDateWiseSubmit(data));
    setSuccessModal(true);
    setTimeout(() => {
      navigate('/my-expense');
      localStorage.removeItem('SelectedExpenseData');
    }, 1000);
  };

  const closeModal = () => {
    setSuccessModal(false);
  };



  // const fetchAttachmentsWithRetry = async (retries = 3) => {
  //   let response = [];
  //   for (let i = 0; i < retries; i++) {
  //     const result = await dispatch(fetchAttachmentDayWise());
  //     response = result.payload || [];
  //     if (response.length > 0) break;
  //   }
  //   console.log("Final Attachments after retries:", response);
  // };attachmentDayWise

  // useEffect(() => {
  //   fetchAttachmentsWithRetry();
  // }, [dispatch]);
  if (isLoading) {
    return <Loader />;
  }

  if (apiError) {
    return <div className="text-red-500">{errorMessage}</div>;
  }

  const dividerClass = 'divider-y h-[0.5px] w-full bg-line-color';

  return (
    <div className="w-screen h-screen flex ">
      <div className="w-full bg-white relative">
        <header className="flex items-center h-10 mt-6">
          <button onClick={handleNavigate} className="pl-4">
            <img
              src={leftArrowIcon}
              alt="leftIcon"
              height={'10px'}
              width={'10px'}
            />
          </button>
          <p className="text-baselg font-bold text-primary pl-24">
            Expense Update
          </p>
        </header>

        <div className="flex justify-end pr-4 pb-2">
          <button
            onClick={toggleDrawer}
            className="font-semibold text-baseSm"
            style={{ color: '#2D5BFF' }}
          >
            Modify
          </button>
          <ModifyRoutes isOpen={isDrawerOpen} onClose={toggleDrawer} />
        </div>

        <div className={dividerClass}></div>

        {dayWiseExpense &&
          dayWiseExpense.length > 0 &&
          dayWiseExpense.map((item, index) => (
            <div key={index}>
              <MyExpenseList id={index} item={item} />
              <div className="divider-y h-[1px] w-full bg-line-color"></div>
            </div>
          ))}
        {/* <div className={dividerClass}></div> */}

        <div
          className="flex justify-between  pl-4 pt-1"
          style={{ backgroundColor: '#F7F0FF', height: '54px' }}
        >
          <div className="pt-1">
            <p className="font-bold text-base">Total Expense Details</p>
            <p className="text-baseXSm">Attach other allowance here</p>
          </div>

          <button
            onClick={() => setShowModal(!showModal)}
            className="px-4 py-2 text-white"
            style={{ borderColor: '#A04D95' }}
          >
            <FileAttachedModal
              onClose={closeModal}
              showModal={showModal}
              onClick={() => setShowModal(!showModal)}
            />
            <img
              src={attachmentIcon}
              alt="attachmentIcon"
              height={'20px'}
              width={'20px'}
            />
          </button>
        </div>

        {/* <div className={dividerClass}></div> */}

        <div className="flex justify-between my-3 pl-4 pr-4">
          <p className="text-baseSm text-[#7A7A7A]">Total Fare</p>
          <p className="text-baseSm text-[#7A7A7A] font-medium">
            ₹ {dayWiseTotalExpense?.fare || 0}
          </p>
        </div>

        {attachmentDayWise &&
          attachmentDayWise.length > 0 &&
          attachmentDayWise.map((item) => (
            <div
              key={item.expenseAttachmentId}
              className="flex justify-between my-3 pl-4 pr-4"
            >
              <p className="text-baseSm text-[#7A7A7A]">{item.expenseType}</p>
              <p className="text-baseSm text-[#7A7A7A] font-medium">
                ₹ {item.fare}
              </p>
            </div>
          ))}

        <div className="divider-y h-[0.5px] w-[92%] bg-line-color ml-4"></div>

        <div className="flex justify-between my-3 pl-4 pr-4">
          <p className="text-baseMd font-semibold">Total Expense</p>
          <p className="text-baseMd text-primary-black font-medium tracking-[1px]">
            ₹ {dayWiseTotalExpense?.total || 0}
          </p>
        </div>

        <div className="divider-y h-[0.5px] w-full bg-line-color"></div>

        {/* <div className={dividerClass}></div> */}

        <div className="p-3 pb-32">
          <p className="text-baseMd font-semibold  text-[#514D4C]">
            Attachments
          </p>
          {/* <div className="divider-y h-[1px] w-full bg-line-color"></div> */}
          {attachmentDayWise &&
            attachmentDayWise?.length > 0 &&
            attachmentDayWise?.map((item) => (
              <div
                key={item.expenseAttachmentId}
                className="flex justify-between p-2 border border-line-color rounded-lg mt-3"
              >
                <div className="flex items-center">
                  <img
                    src={`data:image/png;base64,${item.imageBase64}`}
                    alt="attachment"
                    className="w-12 h-12 rounded-lg object-cover"
                  />
                  <div className="ml-2">
                    <p>{item.expenseType}</p>
                    <p>{item.fare}</p>
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleDeleteAttachment(item)
                  }
                >
                  <div className=" rounded-full mr-3">
                    <img
                      src={removeIcon}
                      alt="removeIcon"
                      height={'25px'}
                      width={'25px'}
                    />
                  </div>
                </button>
              </div>
            ))}
        </div>

        {/* Footer Buttons */}
        {!isDrawerOpen && (

          <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[7rem] w-full flex justify-around items-center gap-3 p-4">
            {/* isDrawerOpen ? 'opacity-0' : 'flex bg-white' */}
            <Button
              label="Back"
              btTextColor="text-primary"
              btBgColor="white"
              onClick={() => handlerNavigate('/my-expense')}
            />
            <Button
              label="Save"
              onClick={handlerSaveUpdate}
              btBgColor="bg-primary"
              btTextColor="text-white"
            />
          </div>
        )}

        {showSuccessModal && (
          <PopupModal
            time={2000} //  2 seconds
            isOpen={showSuccessModal}
            message={'Expense Updated Successfully'}
            type={'success'}
            onClose={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export default MyExpenseDetails;