import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


const Dropdown = ({
  label = 'Select an option',
  options = [],
  name,
  attributeName = '',
  onChange,
  dropdownHeight = 'h-10',
  dropdownWidth = 'w-44',
  attributeValue = '',
  isDisable = false,
  disabledValue = '',
  value = '',
  isOpen,
  onDropdownToggle,
}) => {
  const [selected, setSelected] = useState(value ||label || '');
  const memoizedOptions = useMemo(() => options || [], [options]);

  useEffect(() => {
    if (value === '' || value === undefined || value === null) {
      setSelected(label);
    }
  }, [value, label]);

  useEffect(() => {
    if (isDisable && disabledValue && selected !== disabledValue) {
      setSelected(disabledValue);
    }
  }, [isDisable, disabledValue, selected]);

  const toggleDropdown = () => {
    if (!isDisable) {
      onDropdownToggle(name);
    }
  };

  const handleOptionClick =useCallback((option) => {
    if (!isDisable) {
      setSelected(option[attributeName] || option);
    onChange({ [name]: option[attributeValue] || option });
    onDropdownToggle(null); // Close dropdown after selection
    }
  },    [attributeName, attributeValue, name, onChange, isDisable]
);

  return (
    <div className="relative">
      <button
        type="button"
        className={`flex items-center justify-between border rounded ${dropdownWidth} ${dropdownHeight} px-3`}
        onClick={toggleDropdown}
        disabled={isDisable}
      >
        <span>{selected.length > 10 ? selected.slice(0, 10) + '...' : selected}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </button>
      {isOpen && (
        <div className="absolute bg-white border rounded shadow-md mt-1  overflow-auto z-10">
          {memoizedOptions.map((option, index) => (
            <button
              key={index}
              type="button"
              className="block w-full text-left px-3 py-1 hover:bg-gray-200"
              onClick={() => handleOptionClick(option)}
            >
              {option[attributeName]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;