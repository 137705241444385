import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeExpense, getHomeExpense } from '../../redux/slice/homeSlice';
import Loader from '../../components/modal/Loader';
import SideBar from '../../components/sidebar/SideBar';
import homePageExpenseBarIcon from '/assets/images/homePageExpenseBarIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faChartLine,
  faCircleExclamation,
  faHandHoldingDollar,
} from '@fortawesome/free-solid-svg-icons';
import { ViceversaArrow } from '../sfc/compoments/Icons';
import Calender from '../../components/calender';
import { Link } from 'react-router-dom';
import { fetchSfcTravelingExpences } from '../../redux/slice/sfcTravelingExpences';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import pendingTasksIcon from '../../assets/icons/pending-tasks.png';
import unreportedBillsIcon from '../../assets/icons/unreported-bills.png';
import expenseIcon from '../../assets/icons/expense.png';
import calenderIcon from '../../assets/icons/calender-icon.png';
import einstein from '../../assets/icons/einstein.png';
import travelIcon from '../../assets/icons/travel.png';
import foodIcon from '../../assets/icons/food.png';
import MiscellaneousIcon from '../../assets/icons/Miscellaneous.png';
import accommodationIcon from '../../assets/icons/accomodation.png';
import allowanceIcon from '../../assets/icons/allowance.png';
import { getUserInfo } from '../../redux/slice/authSlice';
import SplashScreen from '../../components/splashScreen/SplashScreen';
import { notificationDetails } from '../../redux/slice/notification';

const Home = () => {
  const [homeExpenseData, setHomeExpenseData] = useState([]);
  const dispatch = useDispatch();
  const {
    userInfo: { employeeId, companyId },
  } = useSelector(getUserInfo);

  const { isLoading, apiError, homeExpense, errorMessage } =
    useSelector(getHomeExpense);
  const { data: sfcTravelingExpences, isLoading: isLoadingExpences } =
    useSelector((state) => state.sfcTravelingExpences);

  const { areaRange: employeeType, data: userInfo } = useSelector(
    (state) => state.sfcUserBasicInfo
  );

  const { data: notification } = useSelector((state) => state.notificationInfo);

  const pendingTaskContainer = useRef(null);
  const [showBills, setShowBills] = useState(false);

  useEffect(() => {
    if (employeeId && companyId) {
      dispatch(fetchHomeExpense());
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (homeExpense && homeExpense !== homeExpenseData) {
      setHomeExpenseData([homeExpense]);
    }
  }, [homeExpense]);

  useEffect(() => {
    dispatch(fetchSfcTravelingExpences());
  }, [userInfo]);

  useEffect(() => {
    dispatch(sfcUserDetails());
    dispatch(notificationDetails());
  }, []);

  function getCurrentTime(type) {
    const now = new Date();

    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const currentTime = `${hours}:${formattedMinutes}`;
    return type === 'time' ? currentTime : ampm;
  }

  return (
    <>
      <div className="bg-[#F5F4F6]">
        <div className="pr-4 pl-4  w-screen">
          {' '}
          {isLoading ? (
            <div className="flex items-center justify-center h-screen">
              <Loader />
            </div>
          ) : apiError ? (
            <div className="flex items-center justify-center h-screen">
              <p className="text-red-500 text-xl">{errorMessage}</p>
            </div>
          ) : (
            homeExpenseData &&
            homeExpenseData.length > 0 &&
            homeExpenseData.map((item, index) => {
              const {
                total,
                allowances,
                previousMonthTotal,
                secondLastYear,
                secondLastMonth,
                previousMonthStatus,
                secondLastTotal,
                secondLastStatus,
                travelExpense,
                misExpense,
                foodExpense,
                accomodationExpense,
                currentMonth,
                previousMonth,
                previousYear,
              } = item;

              return (
                <div key={index}>
                  <SideBar />
                  <div className="h-40 w-full rounded-md flex justify-center items-center mt-2 bg-l-primary">
                    <div className="w-4/6 flex flex-col gap-3 pl-2">
                      <h6 className="text-[#FFEF9D] text-xl">Expenses</h6>
                      <h1 className="text-3xl text-white font-medium tracking-widest">
                        ₹ {total}
                      </h1>
                      <p className="text-white pt-2">Current Month Expense</p>
                    </div>
                    <div>
                      <img
                        src={homePageExpenseBarIcon}
                        alt="homePageExpenseBarIcon"
                        height={'100px'}
                        width={'100px'}
                      />
                    </div>
                  </div>

                  <div className="mt-7">
                    <div className="flex justify-between text-white">
                      <Link to={'/sfc'}>
                        <div className="flex flex-col gap-1 items-center">
                          <span className="p-7 rounded-xl text-center bg-white drop-shadow-[0_3px_5px_rgba(210, 209, 209, 0.44)]">
                            <FontAwesomeIcon
                              icon={faChartLine}
                              size="xl"
                              className="text-primary"
                            />
                          </span>
                          <p className="text-primary font-bold text-[10px] py-3">
                            SFC
                          </p>
                        </div>
                      </Link>
                      <Link to={'/my-expense'}>
                        <div className="flex flex-col gap-1 items-center ">
                          <span className=" p-7 rounded-xl text-center bg-white  drop-shadow-[0_3px_5px_rgba(210, 209, 209, 0.44)]">
                            <img
                              src={expenseIcon}
                              alt="expense"
                              className="w-[24px] h-[24px]"
                            />
                          </span>
                          <p className="text-primary font-bold text-[10px] py-3">
                            Expense
                          </p>
                        </div>
                      </Link>
                      <Link to={'/un-reported-expenses'}>
                        <div className="flex flex-col gap-1 items-center ">
                          <span className=" p-7 rounded-xl text-center bg-white  drop-shadow-[0_3px_5px_rgba(210, 209, 209, 0.44)]">
                            <img
                              src={unreportedBillsIcon}
                              alt="bills"
                              className="w-[24px] h-[24px]"
                            />
                          </span>
                          <p className="text-primary font-bold text-[10px] py-3">
                            Unreported <br />
                            <p className="text-center">bills</p>
                          </p>
                        </div>
                      </Link>
                      <Link>
                        <div
                          className="flex flex-col gap-1 items-center "
                          onClick={() => {
                            pendingTaskContainer.current.scrollIntoView();
                          }}
                        >
                          <span className=" p-7 rounded-xl text-center bg-white drop-shadow-[0_3px_5px_rgba(210, 209, 209, 0.44)]">
                            <img
                              src={pendingTasksIcon}
                              alt="pending tasks"
                              className="w-[24px] h-[24px]"
                            />
                          </span>
                          <p className="text-primary font-bold text-[10px] py-3">
                            Pending <br />
                            <p className="text-center">tasks</p>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3">
                    <div className="flex justify-between mt-7">
                      <p className="font-semibold text-center text-secondary-100 text-sm">
                        Expense History
                      </p>
                      <div></div>
                    </div>

                    <div className="bg-white rounded-xl flex flex-col p-5 gap-4">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-4 items-center">
                          <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                            <img
                              src={calenderIcon}
                              alt="Sep"
                              className="w-[16px] h-[16px]"
                            />
                            <p className="text-primary text-[5px] font-semibold absolute left-[13px] top-[16px]">
                              {previousMonth}
                            </p>
                          </div>
                          <div className="flex flex-col justify-between  gap-2">
                            <p className="text-[#6D6D6D] font-semibold text-sm">
                              {previousMonth} - {previousYear}
                            </p>
                            <p className="text-[#04A314] text-[10px] font-semibold text-xs">
                              {previousMonthStatus || 'pending'}
                            </p>
                          </div>
                        </div>
                        <div className="p-3 text-[#514D4C] text-[14px] font-semibold">
                          ₹ {previousMonthTotal}
                        </div>
                      </div>
                      <div className="mx-3 h-[1px] bg-[#B3B3B3]"></div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-4 items-center">
                          <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                            <img
                              src={calenderIcon}
                              alt="Sep"
                              className="w-[16px] h-[16px]"
                            />
                            <p className="text-primary text-[5px] font-semibold absolute left-[13px] top-[16px]">
                              {secondLastMonth}
                            </p>
                          </div>
                          <div className="flex flex-col justify-between  gap-2">
                            <p className="text-[#6D6D6D] font-semibold text-sm">
                              {secondLastMonth} - {secondLastYear}
                            </p>
                            <p className="text-[#04A314] text-[10px] font-semibold text-xs">
                              {secondLastStatus || 'Pending'}
                            </p>
                          </div>
                        </div>
                        <div className="p-3 text-[#514D4C] text-[14px] font-semibold">
                          ₹ {secondLastTotal}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-52 p-2 pt-4 pb-0 bg-l-primary-100 rounded-xl mt-5 relative">
                    <img
                      src={einstein}
                      alt="Einstein"
                      className="h-5/6 absolute bottom-0 left-[20px]"
                    />
                    <p className="absolute  top-0 pt-8 right-0 p-4 font-bold text-[19px] text-white text-end w-1/2 leading-6">
                      Have you filled your expense
                      <br />
                      <span className="text-[#FFEF9D] text-[50px] leading-[75px]">
                        ???
                      </span>
                    </p>
                  </div>

                  <div className="flex flex-col gap-3">
                    <div className="flex justify-between mt-7">
                      <p className="font-semibold text-center text-secondary-100 text-sm">
                        {currentMonth}-Bill Summary
                      </p>
                      <div></div>
                    </div>

                    <div className="bg-white rounded-xl flex flex-col p-5 gap-4">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-4 items-center">
                          <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                            <img
                              src={travelIcon}
                              alt="travel"
                              className="w-[16px] h-[16px]"
                            />
                          </div>
                          <div className="flex flex-col justify-between  gap-2">
                            <p className="text-[#6D6D6D] font-semibold text-sm">
                              Travel
                            </p>
                          </div>
                        </div>
                        <div className="text-[#514D4C] text-[14px] font-semibold">
                          ₹ {travelExpense}
                        </div>
                      </div>
                      <div className="mx-2 h-[1px] bg-[#B3B3B3]"></div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-4 items-center">
                          <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                            <img
                              src={foodIcon}
                              alt="food"
                              className="w-[16px] h-[16px]"
                            />
                          </div>
                          <div className="flex flex-col justify-between  gap-2">
                            <p className="text-[#6D6D6D] font-semibold text-sm">
                              Food
                            </p>
                          </div>
                        </div>
                        <div className="text-[#514D4C] text-[14px] font-semibold">
                          ₹ {foodExpense}
                        </div>
                      </div>
                      <div className="mx-2 h-[1px] bg-[#B3B3B3]"></div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-4 items-center">
                          <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                            <img
                              src={MiscellaneousIcon}
                              alt="Miscellaneous"
                              className="w-[16px] h-[16px]"
                            />
                          </div>
                          <div className="flex flex-col justify-between  gap-2">
                            <p className="text-[#6D6D6D] font-semibold text-sm">
                              Miscellaneous
                            </p>
                          </div>
                        </div>
                        <div className="text-[#514D4C] text-[14px] font-semibold">
                          ₹ {misExpense}
                        </div>
                      </div>
                      {showBills ? (
                        <>
                          <div className="mx-2 h-[1px] bg-[#B3B3B3]"></div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-4 items-center">
                              <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                                <img
                                  src={accommodationIcon}
                                  alt="Accommodation"
                                  className="w-[16px] h-[16px]"
                                />
                              </div>
                              <div className="flex flex-col justify-between  gap-2">
                                <p className="text-[#6D6D6D] font-semibold text-sm">
                                  Accommodation
                                </p>
                              </div>
                            </div>
                            <div className="text-[#514D4C] text-[14px] font-semibold">
                              ₹ {accomodationExpense}
                            </div>
                          </div>
                          <div className="mx-2 h-[1px] bg-[#B3B3B3]"></div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-4 items-center">
                              <div className="bg-[#F8F9FB] p-[10px] rounded-md relative">
                                <img
                                  src={allowanceIcon}
                                  alt="Allowance"
                                  className="w-[16px] h-[16px]"
                                />
                              </div>
                              <div className="flex flex-col justify-between  gap-2">
                                <p className="text-[#6D6D6D] font-semibold text-sm">
                                  Allowance
                                </p>
                              </div>
                            </div>
                            <div className="text-[#514D4C] text-[14px] font-semibold">
                              ₹ {allowances}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="flex justify-end">
                        <p
                          className="text-primary font-semibold"
                          onClick={() => setShowBills(!showBills)}
                        >
                          {showBills ? 'less' : 'more'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="flex flex-col gap-3 rounded-xl p-5">
          <div className="flex justify-between mt-2">
            <p className="font-semibold text-center text-secondary-100 text-sm">
              My Activities
            </p>
            <div></div>
          </div>

          <div className="flex flex-col justify-center items-center gap-6">
            <div className="flex gap-6">
              <div className=" flex justify-center items-center p-6 bg-primary text-white rounded-xl h-[177px] w-[177px]  leading-[30px]">
                <div className="font-semibold text-2xl">
                  <p className='text-center'>
                    {JSON.stringify(new Date().getDate())} <br />{' '}
                    {homeExpenseData[0]?.currentMonth}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center p-6 bg-primary text-white rounded-xl h-[177px] w-[177px]  leading-[30px]">
                <div className="font-semibold text-2xl">
                  <p className='text-center'>
                    {getCurrentTime('time')} <br /> {getCurrentTime()}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="p-6 bg-primary text-white rounded-xl h-[177px] w-[177px]  leading-[30px]">
                <div className="font-semibold text-2xl">
                  <p className="text-center m-2">
                    {
                      sfcTravelingExpences?.filter(
                        (expences) => expences.status === 'D'
                      ).length
                    }
                  </p>
                  <p className="text-center">
                    Task <br /> Pending
                  </p>
                </div>
              </div>
              <div className="p-6 bg-primary text-white rounded-xl h-[177px] w-[177px]  leading-[30px]">
                <div className="font-semibold text-2xl">
                  <p className="text-center">
                    {notification?.notificationMessage?.length}
                  <br />
                    <span className='text-xl'>Notification</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-5 flex flex-col gap-3" ref={pendingTaskContainer}>
          <div className="flex justify-between mt-2">
            <div className="font-semibold text-center text-secondary-100 text-sm">
              My Tasks
            </div>
          </div>

          <div className="pb-5 overflow-auto my-1 bg-white rounded-xl flex flex-col p-5 gap-4">
            {sfcTravelingExpences?.filter((expences) => expences.status === 'D')
              .length > 0 ? (
              sfcTravelingExpences
                .filter((expences) => expences.status === 'D')
                .map((expense, index) => {
                  return (
                    <div
                      key={index}
                      className="flex mt-3 items-center gap-4 p-4 rounded-md font-medium bg-territory-200 border-l-[6px] border-primary"
                    >
                      <div>{expense?.fromCityName}</div>
                      <div>
                        <ViceversaArrow color="#A04D95" />
                      </div>
                      <div>
                        <p>{expense?.toCityName}</p>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="flex justify-center items-center text-sm">
                No Pending Tasks
              </div>
            )}
          </div>
        </div>

        <div className="px-5 mb-20 pb-28 gap-3">
          <div className="flex justify-between mb-5 mt-6">
            <div className="font-semibold text-center text-secondary-100 text-sm">
              My Calender
            </div>
          </div>
          <div className="p-2 bg-primary-100 rounded-xl mt-6">
            <Calender />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
