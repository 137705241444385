import React, { useRef, useState } from 'react';
import closeIcon from '../../../../../public/assets/images/close.png';

const Input = ({
  label,
  name,
  onChange,
  value,
  width = '40px',
  type = 'text',
  placeholder,
  disabled,
  error,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputTag = useRef(null);

  return (
    <div className={`relative ${error ? 'form-error' : ''}`}>
      <label
        htmlFor={name}
        className={`text-secondary-200 absolute ${
          isFocused || !!value
            ? 'bg-white  px-1 top-[-6px] left-[10px]'
            : 'left-5 top-3'
        } ${disabled ? "text-disable-200" : error ? 'text-red-700' : "text-black"}`}
        style={{ fontSize: `${isFocused || !!value ? '10px' : '12px'}` }}
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        width={width}
        className={`border  w-full p-2 px-4 rounded border-2 outline-none ${
          disabled ? "border-disable text-disable-200 bg-white" : error ? 'border-red-700' : "border-secondary-200"
        }`}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() =>
          setTimeout(() => {
            setIsFocused(false);
          }, 200)
        }
        value={value}
        disabled={disabled}
        ref={inputTag}
        autoComplete="off"
        {...restProps}
      />
      {isFocused && !!value ? (
        <span className="absolute top-3.5 right-4">
          <img
            src={closeIcon}
            alt="Close"
            width={'14px'}
            height={'14px'}
            onClick={() => {
              onChange({ target: { value: '', name } });
              setTimeout(() => {
                inputTag.current.focus();
              }, 200);
            }}
          />
        </span>
      ) : null}
      {error && !disabled ? <p className="text-red-700 mx-2  text-[12px]">{error}</p> : null}
    </div>
  );
};

export default Input;
