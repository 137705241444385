import React from 'react';

const CameraOptionPopup = ({ onCloseModal, onImageHandler, setShowCamera }) => {
  return (
    <div className="absolute w-[248px]  bg-[#F8F8F8] border rounded-[20px] z-10 top-[175px]  left-[20%] shadow-lg">
      <div className="">
        {/* <input
          type="file"
          id="cameraPhotoInput"
          style={{ display: 'none' }}
          accept="image/*"
          multiple
          onChange={onImageHandler}
          onClick={(e) => e.stopPropagation()}
        /> */}
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400"
          onClick={(e) => {e.stopPropagation() , setShowCamera(true) }}
        >
          Camera
        </div>
        <div className="h-[1px] bg-[#949494]"></div>
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400"
          onClick={() => {}}
        >
          Gallery
        </div>
        <div className="h-[1px] bg-[#949494]"></div>
        <div
          className="text-center py-2 text-xs font-medium text-secondary-400"
          onClick={() => onCloseModal(true)}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default CameraOptionPopup;
