import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';


// const userProfileProcesser = (editData) => ({
//   employeeId: editData?.employeeId,
//   employeeName: editData?.employeeName,
//   empCode: editData?.empCode,
//   designation: editData?.designation,
//   city: editData?.city,
//   state: editData?.state,
//   address: editData?.address,
//   mobile: editData?.phoneNumber,
//   phone: editData?.phoneNumber,
//   email: editData?.emailId
// })

export const userDetails = createAsyncThunk(
  'userDetails',
  async (_,{ rejectWithValue, dispatch}) => {
    try {
      const employeeId = JSON.parse(localStorage.getItem("userData"))?.employeeId
      const res = await axios.get(`/employeeProfile`, {
        params: {employeeId: employeeId},
      });
      return successResponseParser(res)
     } catch (error) {
      return rejectWithValue(errorResponseParser(error,dispatch))
     }
  }
);


export const updateUserDetails = createAsyncThunk(
  'updateUserDetails',
  async (editData, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(`/employeeProfileUpdate`, {...editData});
      return successResponseParser(res);
    } catch (error) {
     return  rejectWithValue(errorResponseParser(error,dispatch))
    }
  }
);

export const userBasicInfo = createSlice({
  name: 'userBasicInfo',
  initialState: {
    isLoading: false,
    data: null,
    areaRange: null,
    apiError: '',
    isUserUpdate: false
  },
  reducers: {
    userProfileUpdated: (state) => {
     state.isUserUpdate = state.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.areaRange = action.payload.areaRange;
    });
    builder.addCase(userDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });

    builder.addCase(updateUserDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isUserUpdate = true
    });
    builder.addCase(updateUserDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export const { userProfileUpdated } = userBasicInfo.actions;
export default userBasicInfo.reducer;
