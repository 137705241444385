import React, { useEffect,useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/modal/Loader';
import {
  getAllUnreportedExpense,
  fetchUnReportedData,
  setSelectedAttachment,
  deleteUnReported,
} from '../../redux/slice/unReportedSlice';
// import SideBar from '../../components/sidebar/SideBar';
import deleteIcon from '/assets/images/deleteIcon.png';
import editIconPrimary from '/assets/images/editIconPrimary.png';
import ConfirmationPopup from '../sfc/ConfirmationPopup';
import addIcon from '/assets/images/addIcon.png';
import leftArrowIcon from '/assets/images/leftArrowIcon.png';
import Footer from '../../components/footer/Footer';
import {getAttachmentDaywise}  from '../../redux/slice/myExpenseDayWiseSlice';


const UnReportedExpenses = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const allAttachement = useSelector(getAttachmentDaywise);
  console.log('allAttachementKKK', allAttachement);
  const [isDeleteUnreported, setIsDeleteUnreported] = useState(false);

  const { unReportedData,isLoading, apiError } = useSelector(
    getAllUnreportedExpense
  );

  useEffect(() => {
    dispatch(fetchUnReportedData());
  }, [dispatch, state]);

  const handleAttachment = async(invoice) => {
    if (state) {
      const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
      if (selectedExpenseData) {
        const getDayWiseData = JSON.parse(selectedExpenseData);
        const { activityDate, expenseDetailId, expenseId } = getDayWiseData;
        const {
          employeeId,
          expenseType,
          amount,
          description,
          unReportExpenseId,
          expenseOrSundry,
        } = invoice;

        const attachmentData = {
          activityDate,
          expenseDetailId,
          expenseId,
          employeeId,
          expenseType,
          fare: amount,
          description,
          unReportExpenseId,
          travelMode: 0,
          expenseOrSundry,
        };
        await dispatch(setSelectedAttachment(attachmentData));
        navigate('/my-expense-details');
      }
    }
  };

  const handlerUnreportedEdit = (data) => {
    localStorage.setItem('selectedUnreported', JSON.stringify(data));
    navigate('/captured-image-details', { state: true });
  };

  return (
    <div className="relative w-screen h-screen">
      {isLoading && <Loader />}
    {!isLoading && apiError && (
      <h1 className="text-center text-semibold text-red-500">{apiError}</h1>
    )}

      {!isLoading && !apiError && (
        <div className="p-4">
          <div className="flex items-center w-full pb-4">
            {/* <SideBar /> */}
            <div>
              <img
                src={leftArrowIcon}
                alt="addIcon"
                height={'13px'}
                width={'10px'}
                onClick={() => navigate(-1)}
              />
            </div>
            <p className="text-baselg font-bold text-center text-primary pl-20">
              Unreported Bills
            </p>
          </div>

          <div className="w-full h-[1px] bg-line-color"></div>
          <div>
            <div>
              {unReportedData &&
                unReportedData?.length > 0 &&
                unReportedData?.map((invoice, index) => (
                  <div key={index}>
                    <div
                      key={invoice?.unReportExpenseId}
                      className="rounded-md flex justify-between items-center w-full"
                      onClick={() => handleAttachment(invoice)}
                    >
                      <img
                        src={`data:image/jpg;base64,${invoice?.imageBase64}`}
                        alt="Unrecognized"
                        className="w-[79px] h-[79px] p-2 object-cover rounded-xl"
                      />
                      <div className="w-full p-4">
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-baseXSm text-lightGray">
                            {invoice?.invoiceDate?.slice(0, 10)}
                          </p>
                          <p className="text-primary rounded-lg text-baseSm font-semibold">
                            {invoice?.status }
                          </p>
                        </div>
                        <div className="flex flex-row justify-between">
                          <p className="text-baseMd font-medium text-[#49454F]">
                            {invoice?.invoiceNumber?.length > 10
                              ? `${invoice.invoiceNumber.slice(0, 10)}...`
                              : invoice.invoiceNumber || 'N/A'}
                          </p>
                          <p className="font-semibold text-base text-[#514D4C]">
                          ₹ {invoice?.amount || '0.00'}
                          </p>
                        </div>
                        <div className="flex flex-row justify-between items-center">
                          <p className="text-baseXSm text-lightGray">
                            {invoice.expenseType?.length > 8
                              ? `${invoice?.expenseType.slice(0, 10)}...`
                              : invoice.expenseType || 'N/A'}
                          </p>
                          <div className="flex flex-row justify-between min-w-[15%] items-center">
                        <div onClick={() => handlerUnreportedEdit(invoice)}>
                          <img
                            className="text-baseXSm text-lightGray"
                            src={editIconPrimary}
                            height={12}
                            width={13}
                            alt="edit icon"
                          />
                        </div>

                        <div
                          onClick={() =>
                            setIsDeleteUnreported(invoice.unReportExpenseId)
                          }
                        >
                          <img
                            className="text-baseXSm text-lightGray"
                            src={deleteIcon}
                            height={12}
                            width={13}
                            alt="delete icon"
                          />
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-line-color"></div>
                  </div>
                ))}
            </div>
            <div className="w-full  bg-red-600 mb-16"> </div>
          </div>

          {!unReportedData?.length && (
            <div className="w-full flex items-center justify-center h-[80vh]">
              <button
                className="w-1/3 border-primary border-[1px] p-3 flex items-center justify-evenly rounded-md"
                onClick={() => navigate('/camera')}
              >
                <div className="flex text-primary font-semibold text-baseMd">
                  Add
                </div>
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="add-icon"
                    width={'17px'}
                    height={'17px'}
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      )}
      {isDeleteUnreported && (
        <ConfirmationPopup
          message={'Are you sure, you want to delete?'}
          show={isDeleteUnreported !== false}
          onDenied={() => setIsDeleteUnreported(false)}
          onProceed={async() => {
            await dispatch(deleteUnReported(isDeleteUnreported));
            setIsDeleteUnreported(false);
            dispatch(fetchUnReportedData());
          }}
        />
      )}
      <Footer />
    </div>
  );
};

export default UnReportedExpenses;
