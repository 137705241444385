import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchUnReportedData } from '../../redux/slice/unReportedSlice';
import Dropdown from '../../components/dropdown/Dropdown';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  fetchExpenseType,
  getAllExpenseType,
  getCapturedImage,
  setExpenseSubmit,
  getExpenseSubmitResponse,
  expensedetailUpdate,
} from '../../redux/slice/cameraSlice';
import PopupModal from '../../components/modal/PopupModal';

import { userInfo } from '../../utils/Constant';
const { employeeId, companyId } = userInfo;

// images
import EditIcon from '/assets/images/editPrimaryColorIcon.png';
import removePimaryIcon from '/assets/images/removePimaryIcon.png';
const CapturedDetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUnreportedData = JSON.parse(localStorage.getItem('selectedUnreported')) || {};
  const [isEdit, setEdit] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState('');
  const defaultDate = getUnreportedData?.invoiceDate? getUnreportedData?.invoiceDate: null;
  const convertDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString?.split('-');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = convertDate(defaultDate);
  const [startDate, setStartDate] = useState(
    formattedDate ? new Date(formattedDate) : null
  );
  const { invoiceNumber, expenseType, expenseOrSundry, amount, invoiceDate } =
    getUnreportedData;
  const [currentDropdown, setCurrentDropdown] = useState(null);


  const [invoiceDetails, setInvoiceDetails] = useState({
    todayDate: invoiceDate || '',
    total: amount || '',
    desc: '',
    invoiceNo: invoiceNumber || '',
    expenseType: expenseType || '',
    expenseOrSundry: expenseOrSundry || '',
    amount: amount || '',
  });

  const [errors, setErrors] = useState({});
  const capturedImage = getUnreportedData?.imageBase64
    ? getUnreportedData?.imageBase64
    : useSelector(getCapturedImage);

  const allExpenseType = useSelector(getAllExpenseType);
  const { isLoading, expenseSubmitResponse, apiError, errorMessage } =
    useSelector(getExpenseSubmitResponse);


  useEffect(() => {
    dispatch(fetchExpenseType());
  }, [dispatch]);

  const toggleEditInvoice = () => setEdit((prev) => !prev);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear error on input change
  };

  const handleExpenseTypeChange = (data) => {
    let expenseOrSundry = allExpenseType
      .filter((item) => item.expense_Type === data.expense_Type)
      .map((item) => item.expenseOrSundry)
      .join('');
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      expenseOrSundry,
      expenseType: data.expense_Type,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, expenseType: '' }));
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  const handleNavigate = (path) => {
    localStorage.removeItem('selectedUnreported');
    if (path === '/home') {
      navigate('/home');
    } else {
      navigate('/camera');
    }
  };

  // Handle submit
  const handleSaveClick = async () => {
    invoiceDetails.todayDate = startDate;
    const {
      todayDate: UploadDate,
      desc,
      invoiceNo: InvoiceNumber,
      expenseType: ExpenseType,
      expenseOrSundry,
      amount,
    } = invoiceDetails;

    // Validation check
    const newErrors = {};
    if (!ExpenseType) newErrors.expenseType = 'Please select an expense type';
    if (!InvoiceNumber) newErrors.invoiceNo = 'Invoice Field cannot be empty';
    if (!UploadDate) newErrors.todayDate = 'Date field cannot be empty';
    if (!amount) newErrors.amount = 'Amount field cannot be empty';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const formData = {
      UploadDate,
      fare: amount,
      desc,
      InvoiceNumber,
      ExpenseType,
      EmployeeId: employeeId,
      CompanyId: companyId,
      ImageBase64: capturedImage,
      InvoiceDate: UploadDate,
      expenseOrSundry,
    };

    try {
      const response = await dispatch(setExpenseSubmit(formData));

      if (!response.error) {
        setShowSuccessModal(true);
        setTimeout(() => {
          navigate('/un-reported-expenses');
        }, 1000);
      } else {
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error('Error submitting expense:', error);
      setShowSuccessModal(true);
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setErrors((prevErrors) => ({
      ...prevErrors,
      todayDate: date ? '' : 'Date field cannot be empty',
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handlerUpdateInvoiceDetails = async () => {
    try {
      const unReportAttachmentIdAPI = getUnreportedData?.unReportExpenseId;
      if (unReportAttachmentIdAPI) {
        const {
          invoiceNo: InvoiceNumber,
          expenseType: ExpenseType,
          expenseOrSundry,
          amount,
        } = invoiceDetails;
        const convertedDateFormat =startDate? formatDate(startDate):''
        const formData = {
          UploadDate:convertedDateFormat,
          Amount: amount,
          InvoiceNumber,
          ExpenseType,
          EmployeeId: employeeId,
          CompanyId: companyId,
          InvoiceDate: convertedDateFormat,
          expenseOrSundry,
        };
        let updateResponse = await dispatch(expensedetailUpdate(formData));
        setShowUpdateSuccessModal(updateResponse?.payload?.unreportedUpdateStatus)
        setShowSuccessModal(true);
        setTimeout(() => {
          navigate('/un-reported-expenses');
        }, 1000);
      } else {
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error('Error updating invoice details:', error);
    }
  }

  const handleDropdownToggle = (dropdownName) => {
    setCurrentDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  return (
    <div className="flex flex-col relative pt-8 w-screen h-screen">
      {/* Image Preview */}
      <div className="absolute top-4 right-4">
        <img
          src={removePimaryIcon}
          alt="remove-icon"
          width={'20px'}
          height={'20px'}
          onClick={() => handleNavigate('/home')}
        />
      </div>
      <div className="w-full flex justify-center p-4">
        <img
          src={state ? 'data:image/jpeg;base64,' + capturedImage : capturedImage}
          alt="Captured"
          className="rounded-lg"
          style={{ width: '13rem', height: '15rem' }}
        />
      </div>
      <div className="w-full flex justify-between mt-4 px-4 items-center">
        <p className="text-base font-semibold">Expense Type</p>
        <Dropdown
          label="Expense Type"
          name="expense_Type"
          value={invoiceDetails.expenseType || ''}
          options={allExpenseType}
          attributeValue="expense_Type"
          attributeName="expense_Type"
          onChange={handleExpenseTypeChange}
          isDisable={false}
          disabledValue="Expense Type"
          isOpen={currentDropdown === 'expense_Type'}
          onDropdownToggle={handleDropdownToggle}
          className={errors.expenseType ? 'border border-red-500' : ''}
        />
      </div>
      {errors.expenseType && (
        <p className="text-red-500 text-xs text-right pt-1 pr-4 text-[10px]">
          {errors.expenseType}
        </p>
      )}

      {/* Invoice Details */}
      <div className="w-full space-y-2 mt-4 px-4">
        <div>
          <p className="text-base font-semibold">Invoice Details</p>
        </div>
        <div className="bg-[#F3F3F3] p-4 rounded-lg space-y-1">
          <div className="flex items-center">
            <label className="w-1/3 text-baseSm text-primary-black font-medium">
              Invoice No
            </label>
            <input
              type="text"
              placeholder="Enter Invoice"
              name="invoiceNo"
              value={invoiceDetails.invoiceNo}
              onChange={handleInputChange}
              className={`w-2/3 py-1 bg-[#F3F3F3] text-end font-medium text-[10px] text-primary-black placeholder:text-[12px] placeholder:text-primary placeholder:font-medium border-[1px] ${
                errors.invoiceNo
                  ? 'border-red-500 pt-2 pb-2 placeholder:text-[#F3F3F3]'
                  : 'border-[#F3F3F3]'
              } focus:border-[#6587FF] focus:outline-none`}
              readOnly={!isEdit}
            />
          </div>
          {errors.invoiceNo && (
            <p className="text-red-500 text-[10px] text-right pt-[1px] pb-[1px]">
              {errors.invoiceNo}
            </p>
          )}

          <div className="flex items-center justify-between">
            <label className="w-1/3 text-baseSm text-primary-black font-medium">
              Invoice Date
            </label>
            <div
              className={`relative flex items-center ${
                errors.todayDate
                  ? 'border-[1px] border-red-500 pt-[4px] pb-[4px]    w-[66.66%]  justify-end'
                  : ''
              }`}
            >
              {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg
        aria-hidden="true"
        className="w-5 h-5 text-gray-500 dark:text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
          clipRule="evenodd"
        />
      </svg>
   </div> */}
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                placeholderText="Select Date"
                disabledKeyboardNavigation
                dateFormat="dd/MM/yyyy"
                className={`w-full bg-[#F3F3F3] text-end text-primary-black font-medium text-[10px] placeholder:text-[12px] placeholder:text-primary placeholder:font-medium ${
                  errors.todayDate
                    ? 'border-red-500 placeholder:text-[#F3F3F3]'
                    : 'border-[#F3F3F3]'
                } focus:border-[#6587FF] focus:outline-none`}
                popperPlacement="bottom-end"
                onChangeRaw={(e) => e.preventDefault()}
              />
            </div>
          </div>
          {errors.todayDate && (
            <p className="text-red-500 text-[10px] text-right pt-[1px] pb-[1px]">
              {errors.todayDate}
            </p>
          )}

          <div className="flex items-center">
            <label className="w-1/3 text-baseSm text-primary-black font-medium">
              Amount
            </label>
            <input
              type="text"
              name="amount"
              placeholder="Enter Amount"
              value={invoiceDetails.amount}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e);
                }
              }}
              className={`w-2/3 py-1 bg-[#F3F3F3] text-end text-primary-black font-medium text-[10px] placeholder:text-[12px] placeholder:text-primary placeholder:font-medium border-[1px] ${
                errors.amount
                  ? 'border-red-500 placeholder:text-[#F3F3F3] pt-2 pb-2'
                  : 'border-[#F3F3F3]'
              } focus:border-[#6587FF] focus:outline-none`}
            />
          </div>
          {errors.amount && (
            <p className="text-red-500 text-[10px] text-right pt-[1px] pb-[1px]">
              {errors.amount}
            </p>
          )}

          <div className="w-full flex border-t-[1px] border-line-color"></div>
          <div className="flex items-center w-full">
            <label className="w-1/3 text-base font-medium text-primary-black">
              Total
            </label>
            <input
              type="text"
              name="total"
              value={`₹ ${invoiceDetails.amount}`}
              onChange={handleInputChange}
              className="w-2/3 py-1 bg-[#F3F3F3] text-end text-baseMd text-primary-black font-medium"
              readOnly={!isEdit}
            />
          </div>
        </div>
      </div>

      {/* Footer */}

      {state && getUnreportedData?.invoiceNumber ? (
        <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[7rem]  w-full flex justify-around items-center gap-3 p-4">
          <Button
            btFullwidth={true}
            label="update"
            btBgColor="bg-primary"
            btTextColor="text-white"
            onClick={handlerUpdateInvoiceDetails}
          />
        </div>
      ) : (
        <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[8rem] sm:h-[8rem] w-full flex flex-row sm:flex-row justify-around items-center gap-3 p-4">
          <Button
            label="Retake"
            btTextColor="text-primary"
            btBgColor="white"
            onClick={() => handleNavigate('/camera')}
          />
          <Button
            label="Save"
            onClick={handleSaveClick}
            btBgColor="bg-primary"
            btTextColor="text-white"
          />
        </div>
      )}
      {/* <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[8rem] sm:h-[8rem] w-full flex flex-row sm:flex-row justify-around items-center gap-3 p-4">
        <Button
          label="Retake"
          btTextColor="text-primary"
          btBgColor="white"
          onClick={() => handleNavigate('/camera')}
        />
        <Button
          label="Save"
          onClick={handleSaveClick}
          btBgColor="bg-primary"
          btTextColor="text-white"
        />
      </div> */}
      {showSuccessModal && (
        <PopupModal
          time={2000}
          isOpen={showSuccessModal}
          message={ showUpdateSuccessModal || 'Saved Successfully'}
          type={apiError ? 'Fail' : 'success'}
          onClose={handleSuccessModalClose}
        />
      )}
    </div>
  );
};

export default CapturedDetails;
