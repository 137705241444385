import { faAngleLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUserDetails,
  userDetails,
  userProfileUpdated,
} from '../../redux/slice/userProfile';
import Loader from '../../components/modal/Loader';
import defaultUserIcon from '../../../public/assets/images/defaultUserIcon.png';
import Input from './components/Input';
import CameraOptionPopup from './components/CameraOptionPopup';
import SuccessfullPopup from '../sfc/SuccessfullPopup';
import editIcon from '../../../public/assets/images/edit.png';
import ProfileCamera from './components/ProfileCamera';

const initialUserProfileDetails = {
  address: '',
  phoneNumber: '',
  pincode: '',
  emailId: '',
};

const validateUserForm = ({ address, phoneNumber, pincode, emailId }) => {
  const error = {};
  if (!address) error.address = 'Please Select';
  if (!phoneNumber) error.phoneNumber = 'Please Select';
  if (!pincode) error.pincode = 'Please Select';
  if (!emailId) error.emailId = 'Please Select';
  return [!!address && !!phoneNumber && !!pincode && !!emailId, error];
};

const User = () => {
  const [onChange, setOnChange] = useState(true);
  const dispatch = useDispatch();
  const { isLoading: loading, data: userProfileDetails } = useSelector(
    (state) => state.userDetails
  );

  // const { isLoading: updateUserDetailsLoading } = useSelector(
  //   (state) => state.updateUserDetails
  // );

  const { isUserUpdate } = useSelector((state) => state.userDetails);

  const [userUpdatedDetails, setUserUpdatedDetails] = useState({});
  const [userValidateError, setUserValidateError] = useState({});
  const [showModal, setShowModal] = useState(true);
  const [showCamera, setShowCamera] = useState(false);
  const [profilePic, setProfilePic] = useState(defaultUserIcon);

  useEffect(() => {
    if (userProfileDetails?.empCode) {
      setUserUpdatedDetails({
        ...userProfileDetails,
      });
    }
  }, [userProfileDetails]);

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  const onUpdateHandler = () => {
    const [validate, errors] = validateUserForm(userUpdatedDetails);
    if (!validate) {
      setUserValidateError(errors);
      return;
    }

    if (!onChange) {
      dispatch(updateUserDetails(userUpdatedDetails));
    }
  };

  const onChangeHandler = ({ target: { value, name } }) => {
    setOnChange(false);
    setUserUpdatedDetails({ ...userUpdatedDetails, [name]: value });
    setUserValidateError({ ...userValidateError, [name]: '' });
  };

  const onImageHandler = (e) => {
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : isUserUpdate ? (
        <SuccessfullPopup
          displayText={'Updated'}
          onClose={() => {
            dispatch(userProfileUpdated(false)), setOnChange(true);
          }}
        />
      ) : showCamera ? (
        <ProfileCamera setShowCamera={setShowCamera} setProfilePic={setProfilePic} />
      ) : (

        <div
          className="w-full h-full flex flex-col"
          onClick={() => setShowModal(true)}
        >
          {!showModal ? (
            <CameraOptionPopup
              onCloseModal={setShowModal}
              onImageHandler={onImageHandler}
              setShowCamera={setShowCamera}
            />
          ) : null}
          <div className="flex flex-col items-center">
            <div className="w-full h-full flex px-6 py-5">
              <div>
                <Link to="/home" className="grow">
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    color="#A04D95"
                    size="lg"
                  />
                </Link>
              </div>
              <p className="text-primary font-semibold text-lg grow text-center">
                Profile
              </p>
              <div></div>
            </div>
            <div className="relative">
              <img
                src={profilePic}
                alt=""
                width={'100px'}
                height={'100px'}
                className="rounded-full"
              />
              <span
                className="absolute left-10 top-[79px]"
                onClick={(e) => {
                  e.stopPropagation(), setShowModal((prev) => !prev);
                }}
              >
                <img src={editIcon} alt="edit" width={'18px'} height={'18px'} />
              </span>
            </div>
          </div>
          <div className="w-full h-full px-6 flex flex-col gap-5 mt-8">
            <Input
              label={'Full Name'}
              name={'employeeName'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.employeeName}
              disabled
            />
            <Input
              label={'Emp Code'}
              name={'empCode'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.empCode}
              disabled
            />
            <Input
              label={'Designation'}
              name={'designation'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.designation}
              disabled
            />
            <Input
              label={'Address'}
              name={'address'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.address}
              error={userValidateError?.address}
            />
            <div className="flex gap-2">
              <Input
                label={'City'}
                name={'city'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.city}
                disabled
              />

              <Input
                label={'State'}
                name={'state'}
                onChange={onChangeHandler}
                value={userUpdatedDetails?.state}
                disabled
              />
            </div>
            <Input
              label={'Pin'}
              name={'pincode'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.pincode}
              error={userValidateError?.pincode}
            />
            <Input
              label={'Phn no'}
              name={'phoneNumber'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.phoneNumber}
              error={userValidateError?.phoneNumber}
            />
            <Input
              label={'Email Id'}
              name={'emailId'}
              onChange={onChangeHandler}
              value={userUpdatedDetails?.emailId}
              error={userValidateError?.emailId}
            />
          </div>
          <div className="w-full flex justify-end items-center bg-white p-5 shadow-top py-[32px]">
            <button
              className={`w-full ${
                onChange ? 'bg-lightGray' : 'bg-primary'
              } text-white rounded-full p-3 font-bold`}
              onClick={onUpdateHandler}
            >
              Update
            </button>
          </div>
        </div>
        // </div>
      )}
    </>
  );
};

export default User;
